import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import favicon from "../images/favicon.ico"
// import Path from "path"

import Header from "../components/header"
import Footer from "../components/footer"

const Layout = ({ path, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(
        filter: {
          frontmatter: { type: { eq: "solution" }, promo: { eq: "true" } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              subtitle
            }
          }
        }
      }
    }
  `)

  console.log(path)
  console.log(data.allMarkdownRemark)
  const currentPage = path
  const solutions = data.allMarkdownRemark

  return (
    <div>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          { name: "description", content: "Sample" },
          { name: "keywords", content: "chatbot, AI" },
        ]}
        link={[
          { rel: "shortcut icon", type: "image/x-icon", href: `${favicon}` },
        ]}
      />
      <Header nowPage={currentPage} />
      <div>{children}</div>
      <Footer promosol={solutions} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
