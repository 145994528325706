import React, { Component } from "react"
import { Link } from "gatsby"
// import PropType from "prop-types"

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showMenu: false,
      showSubmenu: false,
    }
    this.handleClick = this.handleClick.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.closeSubmenu = this.closeSubmenu.bind(this)
  }

  handleClick() {
    this.setState(prevState => ({
      showMenu: !prevState.showMenu,
    }))
  }

  onMouseEnter() {
    this.setState({ showSubmenu: true })
  }

  onMouseLeave(e) {
    console.log(e)
    this.setState({ showSubmenu: false })
  }

  closeSubmenu(e) {
    // console.log("abc")
    this.setState({ showSubmenu: false })
  }

  render() {
    return (
      <header>
        <div className="header-wrap">
          <Link to="/" className="logo">
            Service AI
          </Link>
          <div className="nav">
            <label
              htmlFor="nav-menu-toggle"
              onClick={this.handleClick}
              className={this.state.showMenu ? "open" : ""}
            ></label>
            <input type="checkbox" id="nav-menu-toggle" />
            <div className="nav-bar">
              <div className="nav-item">
                <Link to="/" activeClassName="active">
                  首页
                </Link>
              </div>
              <div
                className={
                  this.state.showSubmenu
                    ? "nav-item-group open"
                    : "nav-item-group"
                }
              >
                <h4
                  onMouseEnter={this.onMouseEnter}
                  className={this.props.nowPage === "pd" ? "current" : ""}
                >
                  产品
                </h4>
                <div className="nav-submenu" onMouseLeave={this.onMouseLeave}>
                  <div className="submenu-toggle"></div>
                  <div
                    className="submenu-close-area"
                    onMouseEnter={this.closeSubmenu}
                  ></div>
                  <div className="nav-items">
                    <div className="nav-item luma">
                      <Link
                        to="/products/product-luma"
                        activeClassName="active"
                      >
                        Luma虚拟助理
                      </Link>
                    </div>
                    <div className="nav-item auto-eng">
                      <Link
                        to="/products/product-auto"
                        activeClassName="active"
                      >
                        Luma自动化
                      </Link>
                    </div>
                    <div className="nav-item ism">
                      <Link to="/products/product-ism" activeClassName="active">
                        Luma服务管理
                      </Link>
                    </div>
                    <div className="nav-item asp">
                      <Link
                        to="/products/product-AIOps"
                        activeClassName="active"
                      >
                        Luma智能运维
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nav-item">
                <Link
                  to="/solutions"
                  activeClassName="active"
                  partiallyActive={true}
                >
                  解决方案
                </Link>
              </div>
              <div className="nav-item">
                <Link
                  to="/blog"
                  activeClassName="active"
                  partiallyActive={true}
                >
                  最新资讯
                </Link>
              </div>
              <div className="nav-item">
                <Link to="/about" activeClassName="active">
                  关于我们
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
